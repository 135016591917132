<template>
  <v-container class="pa-0">
    <v-card class="mx-auto mt-0 mb-0" left light>
      <v-card-text class="pa-0">
        <v-row>
          <v-col
            v-if="!shipmentDetailEdit"
            class="hgt350 pt-0 px-0"
            cols="12"
            lg="6"
            md="6"
            sm="12"
          >
            <load-tracking />
            <v-container class="pb-1 pt-0 px-0">
              <v-row>
                <v-col class="px-0 pt-0" cols="3">
                  <div v-if="shipmentDetail.loadStops" style="width: 100%">
                    <v-chip color="orange" label text-color="white">
                      <v-icon left> mdi-stop</v-icon>
                      <b>{{ shipmentDetail.loadStops.length }} Stop(s)</b>
                    </v-chip>
                  </div>
                  <div
                    :class="shipmentDetail.loadStops ? 'pt-1' : 'pt-3'"
                    class="pl-2"
                  >
                    {{ shipmentDetail.id }}
                  </div>
                </v-col>
                <v-col class="px-0" cols="1">
                  <v-img
                    v-show="shipmentDetail.loadboard"
                    contain
                    height="20"
                    src="@/assets/DAT-logo-blue.svg"
                    width="60"
                  />
                </v-col>
                <v-col class="pl-0 pr-0" cols="1">
                  <v-img
                    v-show="shipmentDetail.loadboard"
                    contain
                    height="20"
                    src="@/assets/truckstop.png"
                    width="20"
                  />
                </v-col>
                <v-col cols="2" class="pl-0 pr-0">
                  <loadboard-manager
                    v-if="loadId"
                    :load-id="loadId"
                    :loadboard-post-statuses="loadboardPostStatuses"
                    :load-status="shipmentDetail.loadStatusDisplay"
                    @update-statuses="handleUpdateStatuses"
                  />
                </v-col>
                <v-col cols="5" class="text-right pr-0 pl-0">
                  <load-status-button
                    :agent-detail="agentDetail"
                    :load-status-items="loadStatusChoices"
                    :shipment-detail="shipmentDetail"
                    :disable-click="isLoadStatusChangeDisabled"
                  />
                  <v-btn
                    v-if="!disableStatusButton"
                    color="grey"
                    small
                    text
                    @click="shipmentDetailEditing()"
                  >
                    Edit
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <v-container class="pb-1 pt-0">
              <v-list-item-subtitle class="grey--text text--darken-1">
                Customer
              </v-list-item-subtitle>
              <v-list-item-title
                v-if="shipmentDetail.customer"
                class="text-h5 pl-0 grey--text text--darken-4"
              >
                <router-link
                  :to="{
                    name: 'Customer Detail',
                    params: {
                      id: shipmentDetail.customer.id,
                    },
                  }"
                >
                  {{ shipmentDetail.customer.name }}
                  <v-tooltip v-if="invoicePaidText" right>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        class="ml-0 text-truncate truncate"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon medium> mdi-cash-check </v-icon>
                      </span>
                    </template>
                    <div class="box">
                      {{ invoicePaidText }}
                    </div>
                  </v-tooltip>
                </router-link>
              </v-list-item-title>
              <v-list-item-title
                v-else
                class="text-h5 pl-0 grey--text text--darken-4"
              >
                Customer not assigned
              </v-list-item-title>
            </v-container>
            <div v-if="$vuetify.breakpoint.lgAndUp && !carrierMatchListCard">
              <progress-stepper />
            </div>
            <div v-else>
              <v-container>
                <v-row justify="center" no-gutters>
                  <v-col cols="4">
                    <p class="mb-1 grey--text text--darken-1 text-center">
                      Pickup Date
                    </p>
                    <v-card-text
                      class="
                        text--regular
                        pa-0
                        grey--text
                        text--darken-4 text-center
                      "
                    >
                      <render-date :date="shipmentDetail.pickupDate" />
                    </v-card-text>
                  </v-col>
                  <v-col class="text-center" cols="2">
                    <v-icon class="pt-3"> mdi-arrow-right-bold</v-icon>
                  </v-col>
                  <v-col cols="4">
                    <p class="mb-1 grey--text text--darken-1 text-center">
                      Delivery Date
                    </p>
                    <v-card-text
                      class="
                        text--regular
                        pa-0
                        grey--text
                        text--darken-4 text-center
                      "
                    >
                      <render-date :date="shipmentDetail.deliveryDate" />
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-container>
            </div>
            <v-row class="pl-3 pt-1" no-gutters>
              <v-col class="xs pb-2" cols="6" md="4" sm="4">
                <p class="mb-1 grey--text text--darken-1">Equipment</p>
                <v-card-text
                  class="text--regular pa-0 grey--text text--darken-4"
                >
                  {{ shipmentDetail.equipmentType }}
                </v-card-text>
              </v-col>
              <v-col class="xs pb-2" cols="6" md="4" sm="4">
                <p class="mb-1 grey--text text--darken-1">Order Type</p>
                <v-card-text
                  v-if="shipmentDetail.orderTypeDisplay"
                  class="text--regular pa-0 grey--text text--darken-4"
                >
                  {{ shipmentDetail.orderTypeDisplay }}
                </v-card-text>
                <v-card-text
                  v-else
                  class="text--regular pa-0 grey--text text--darken-4"
                >
                  -
                </v-card-text>
              </v-col>
              <v-col class="xs pt-0 pb-2" cols="6" md="4" sm="4">
                <p class="mb-1 grey--text text--darken-1">Mode</p>
                <v-card-text
                  v-if="shipmentDetail.mode"
                  class="text--regular pa-0 grey--text text--darken-4"
                >
                  <tooltip :tooltip-content="shipmentDetail.modeDisplay" />
                </v-card-text>
                <v-card-text
                  v-else
                  class="text--regular pa-0 grey--text text--darken-4"
                >
                  -
                </v-card-text>
              </v-col>
              <v-col class="xs pb-2" cols="6" md="4" sm="4">
                <p class="mb-1 grey--text text--darken-1">Sales Rep</p>
                <v-card-text
                  v-if="shipmentDetail.agent"
                  class="text--regular pa-0 grey--text text--darken-4"
                >
                  {{ shipmentDetail.agent.name }}
                </v-card-text>
                <v-card-text
                  v-else
                  class="text--regular pa-0 grey--text text--darken-4"
                >
                  -
                </v-card-text>
              </v-col>
              <v-col class="xs pb-2" cols="6" md="4" sm="4">
                <p class="mb-1 grey--text text--darken-1">Account Manager</p>
                <v-card-text
                  v-if="shipmentDetail.customer"
                  class="text--regular pa-0 grey--text text--darken-4"
                >
                  {{ shipmentDetail.customer.account_manager_display }}
                </v-card-text>
                <v-card-text
                  v-else
                  class="text--regular pa-0 grey--text text--darken-4"
                >
                  -
                </v-card-text>
              </v-col>
              <v-col class="xs pb-2" cols="6" md="4" sm="4">
                <p class="mb-1 grey--text text--darken-1">CSR</p>
                <v-card-text
                  v-if="shipmentDetail.carrierSalesRep"
                  class="text--regular pa-0 grey--text text--darken-4"
                >
                  {{ shipmentDetail.carrierSalesRep.name }}
                </v-card-text>
                <v-card-text
                  v-else
                  class="text--regular pa-0 grey--text text--darken-4"
                >
                  -
                </v-card-text>
              </v-col>
              <v-col class="xs pb-2" cols="6" md="4" sm="4">
                <p class="mb-1 grey--text text--darken-1">Customer Ref. ID</p>
                <v-card-text
                  v-if="shipmentDetail.customerReferenceId"
                  class="text--regular pa-0 grey--text text--darken-4"
                >
                  {{ shipmentDetail.customerReferenceId }}
                </v-card-text>
                <v-card-text
                  v-else
                  class="text--regular pa-0 grey--text text--darken-4"
                >
                  -
                </v-card-text>
              </v-col>
              <v-col class="xs pb-2" cols="6" md="4" sm="4">
                <p class="mb-1 grey--text text--darken-1">PO Number</p>
                <v-card-text
                  v-if="shipmentDetail.poNumber"
                  :title="shipmentDetail.poNumber"
                  class="text--regular pa-0 grey--text text--darken-4"
                  style="
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                  "
                >
                  <tooltip :tooltip-content="shipmentDetail.poNumber" />
                </v-card-text>
                <v-card-text
                  v-else
                  class="text--regular pa-0 grey--text text--darken-4"
                >
                  -
                </v-card-text>
              </v-col>
              <v-col class="xs pb-2" cols="6" md="4" sm="4">
                <p class="mb-1 grey--text text--darken-1">Carrier Invoice #</p>
                <v-card-text
                  v-if="shipmentDetail.carrierInvoiceNumber"
                  class="text--regular pa-0 grey--text text--darken-4"
                >
                  {{ shipmentDetail.carrierInvoiceNumber }}
                </v-card-text>
                <v-card-text
                  v-else
                  class="text--regular pa-0 grey--text text--darken-4"
                >
                  -
                </v-card-text>
              </v-col>
              <v-col class="xs pb-2" cols="6" md="4" sm="4">
                <p class="mb-1 grey--text text--darken-1">Billing Ref. Number</p>
                <v-card-text
                  class="text--regular pa-0 grey--text text--darken-4"
                >
                  {{ shipmentDetail.billingReferenceNumber || "-" }}
                </v-card-text>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            v-else
            class="xs hgt350 pt-0 px-0"
            cols="12"
            lg="6"
            md="6"
            sm="12"
            xl="6"
          >
            <shipment-detail-edit />
          </v-col>
          <v-col class="pa-0 pl-3" cols="12" lg="6" md="6" sm="12" xl="6">
            <shipment-map />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex"
import ShipmentMap from "@/views/pages/load-detail-components/ShipmentMap"
import Tooltip from "@/views/pages/components/Tooltip"
import ShipmentDetailEdit from "@/views/pages/load-detail-components/edit-components/ShipmentDetailEdit"
import progressStepper from "@/views/pages/components/ProgressStepper"
import RenderDate from "@/views/pages/components/RenderDate"
import LoadTracking from "@/views/pages/LoadTracking"
import LoadStatusButton from "@/views/pages/components/LoadStatusButton"
import common from "@/utils/common"
import { LOAD_STATUS, CREATE_METHOD } from "@/assets/constants"
import LoadboardManager from '@/views/pages/components/LoadboardManager'

export default {
  name: "ShipmentDetail",
  components: {
    ShipmentMap,
    Tooltip,
    progressStepper,
    ShipmentDetailEdit,
    RenderDate,
    LoadTracking,
    LoadStatusButton,
    LoadboardManager,
  },
  data() {
    return {
      buttonColors: {
        plan: false,
        available: false,
        booked: false,
        confirmed: false,
        picked: false,
        delivered: false,
        claim: false,
        invoiced: false,
        paid: false,
        pickOnTrack: false,
        pickOffTrack: false,
        deliveryOnTrack: false,
        deliveryOffTrack: false,
        readyToBill: false,
        needsAppt: false,
        loading: false,
        unloading: false,
        loadStatusItems: [],
      },
    }
  },
  computed: {
    ...mapGetters("loadDetail", [
      "shipmentDetail",
      "shipmentDetailEdit",
      "loadStatusChoices",
      "loadInfo",
      "shipperInfo",
      "consigneeInfo",
      "loadId",
    ]),
    ...mapGetters("carrierMatching", ["carrierMatchListCard"]),
    ...mapGetters("generic", ["agentDetail"]),
    disableStatusButton() {
      return common.methods.disableButtonBaseOnLoadStatus(
        this.shipmentDetail,
        this.agentDetail
      )
    },
    isLoadStatusChangeDisabled() {
      return Boolean(
        this.shipmentDetail.createMethod === CREATE_METHOD.CUSTOMER_USER &&
          [LOAD_STATUS.PLAN, LOAD_STATUS.QUOTED, LOAD_STATUS.DRAFT].includes(
            this.shipmentDetail.loadStatus
          )
      )
    },
    invoicePaidText() {
      if (
        (this.shipmentDetail.invoiceAmount ||
          this.shipmentDetail.invoiceAmount > 0) &&
        this.shipmentDetail.receivedAt
      ) {
        return `Paid $${this.shipmentDetail.invoiceAmount} on ${this.shipmentDetail.receivedAt}`
      } else {
        return null
      }
    },
    loadboardPostStatuses() {
      return this.loadInfo.loadboardPostStatuses || []
    },
  },
  methods: {
    shipmentDetailEditing() {
      this.$store.commit("loadDetail/setShipmentDetailEditing", true)
    },
    async changeLoadboardStatus() {
      const payload = {
        loadId: this.shipmentDetail.id,
        posted: this.shipmentDetail.loadboard,
      }
      try {
        await this.$store.dispatch(
          "loadDetail/UPDATE_LOADBOARD_STATUS",
          payload
        )
      } catch (e) {
        this.shipmentDetail.loadboard = false
      }
    },
    handleUpdateStatuses(statuses) {
      this.$store.commit("loadDetail/setLoadInfo", {
        ...this.loadInfo,
        loadboardPostStatuses: statuses
      })
    }
  },
}
</script>

<style scoped>
.cargo-name {
  font-size: 14px;
}

.hgt350 {
  height: 350px;
  overflow-y: auto;
  padding-bottom: 0;
}

.v-application a {
  color: #000;
  text-decoration: none;
}

.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: red !important;
  color: #ffffff !important;
}

.row {
  margin: 0;
}
</style>

<style>
.v-chip .v-chip__content {
  font-size: 12px;
}

.v-chip--label {
  border-radius: 4px 0 0 0 !important;
}
</style>
