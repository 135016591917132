<template>
  <div class="simple-location-autocomplete">
    <v-autocomplete
      v-model="location"
      :items="locationList"
      :loading="loading"
      :search-input.sync="search"
      color="info"
      clearable
      item-text="name"
      item-value="id"
      label="Location"
      return-object
      required
      close-on-content-click
      class="pt-0 pb-0"
      item-width="200"
      :filter="(v) => v"
      @keydown.enter="fireEnterEvent()"
      @blur="pushLocation()"
      @click:clear="removeLocation()"
    >
      <v-list-item slot="append-item">
        <v-btn text small @click="locationForm()">
          <v-icon>mdi-plus</v-icon> Add Location
        </v-btn>
      </v-list-item>
      <template v-slot:item="data">
        <template v-if="data.item.disabled === true">
          <v-list-item-content v-text="data.item.name" />
        </template>
        <template v-else>
          <v-list-item-avatar>
            {{ shortLocationName(data.item.name) }}
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name" />
            <v-list-item-subtitle v-html="data.item.address_preview" />
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import debounce from "lodash/debounce"
import api from "@/api/api"
import { mapGetters } from "vuex"

export default {
  name: "SimpleLocationAutoComplete",
  props: {
    customerId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      selectValue: false,
      search: "",
      locations: [],
      suggestedLocations: [],
      isEnterEvent: false,
      selectedLocation: null,
    }
  },
  computed: {
    ...mapGetters("generic", {
      selectedLocationData: "selectedLocation",
    }),
    locationList() {
      const searchedLocations = []
      if (this.customerId) {
        if (this.locations.length > 0) {
          const customerLocations = this.locations.map((location) => {
            const name = location.name
            return Object.assign({}, location, { name })
          })
          customerLocations.unshift({
            name: "Customer Locations",
            disabled: true,
          })
          for (let i = 0; i < customerLocations.length; i++) {
            searchedLocations.push(customerLocations[i])
          }
        }
      }
      if (this.suggestedLocations.length > 0) {
        const locationData = this.suggestedLocations.map((location) => {
          const name = location.name
          return Object.assign({}, location, { name })
        })
        if (this.customerId) {
          locationData.unshift({
            name: "Suggested Locations",
            disabled: true,
          })
        }
        for (let i = 0; i < locationData.length; i++) {
          searchedLocations.push(locationData[i])
        }
      }
      return searchedLocations
    },
    location: {
      get() {
        return this.selectValue ? this.selectValue : null
      },
      set(newValue) {
        this.selectValue = newValue
        this.selectedLocation = newValue
        if (this.isEnterEvent === true) {
          this.fireEnterEvent()
        }
      },
    },
  },
  watch: {
    search(val) {
      if (!val || val.length === 0) {
        this.locations = []
        return
      }
      if (this.location) {
        if (val !== this.location.name) {
          if (this.loading) return
          this.loading = true
          this.getSearch()
        }
      } else {
        if (this.loading) return
        this.loading = true
        this.getSearch()
      }
    },
    location: function (val) {
      if (!val) {
        this.location = null
      }
    },
    selectedLocationData() {
      if (this.selectedLocationData) {
        this.location = {
          id: this.selectedLocationData.id,
          name: this.selectedLocationData.name,
          text: this.selectedLocationData.name,
        }
        this.locations = [
          {
            id: this.selectedLocationData.id,
            name: this.selectedLocationData.name,
            text: this.selectedLocationData.name,
          },
        ]
      }
    },
  },
  methods: {
    locationForm() {
      this.$store.commit("create/setLocationDialog", true)
    },
    getSearch: debounce(function () {
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/locations/location-front-end-autocomplete/`,
          {
            params: {
              q: this.search,
            },
          }
        )
        .then((res) => {
          if (this.customerId) {
            this.getCustomerLocations()
          }
          this.suggestedLocations = res.data.results
          this.loading = false
        })
        .catch((err) => {
          const payload = {
            status: true,
            text: "Could not retrieve locations.",
            error: err,
          }
          this.$store.commit("main/SET_SNACKBAR", payload)
          this.loading = false
        })
    }, 1000),
    getCustomerLocations: debounce(function () {
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/locations/customer-location-autocomplete/`,
          {
            params: {
              q: this.search,
              customer_company_id: this.customerId,
            },
          }
        )
        .then((res) => {
          this.locations = res.data.results
          this.loading = false
        })
        .catch((err) => {
          const payload = {
            status: true,
            text: "Could not retrieve locations.",
            error: err,
          }
          this.$store.commit("main/SET_SNACKBAR", payload)
          this.loading = false
        })
    }, 1000),
    removeLocation() {
      this.location = null
      this.$store.commit("generic/setLocationId", null)
      this.$emit("event", { location: null, flag: 3 })
    },
    shortLocationName(value) {
      let sortName = ""
      if (value !== undefined && value !== null) {
        sortName = value.match(/\b(\w)/g).join("")
        sortName = sortName.charAt(0) + sortName.charAt(1)
      }
      return sortName
    },
    pushLocation() {
      if (this.location) {
        this.$store.commit("generic/setLocationId", this.location.id)
        this.$emit("event", { location: this.location, flag: 2 })
      }
    },
    fireEnterEvent() {
      this.isEnterEvent = true
      if (
        this.selectedLocation &&
        this.selectedLocation.id !== undefined &&
        this.selectedLocation.id !== null &&
        this.isEnterEvent === true
      ) {
        this.$store.commit("generic/setLocationId", this.selectedLocation.id)
        this.$emit("event", {
          location: this.selectedLocation,
          flag: 1,
        })
        this.isEnterEvent = false
        this.location = null
      }
    },
  },
}
</script>
