<template>
  <div>
    <v-autocomplete
      :value="equipmentType"
      :items="equipmentTypes"
      item-text="name"
      item-value="id"
      label="Equipment Type"
      @change="pushEquipmentType"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  name: "EquipmentTypeAutoComplete",
  props: {
    equipment: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      equipmentType: null,
    }
  },
  computed: {
    ...mapGetters("loadDetail", ["shipmentDetail"]),
    ...mapGetters("generic", ["equipmentTypes"]),
  },
  mounted() {
    if (this.equipment) {
      this.equipmentType = {
        name: this.equipment.name,
        id: this.equipment.id,
      }
      this.$store.commit("generic/setEquipment", this.equipmentType.name)
    } else {
      this.equipmentType = {
        name: this.shipmentDetail.equipmentType,
        id: this.shipmentDetail.equipmentType,
      }
    }
  },
  methods: {
    pushEquipmentType(equipmentType) {
      if (equipmentType) {
        this.$store.commit("generic/setEquipment", equipmentType)
      }
    },
  },
}
</script>
