<template>
  <v-container class="pt-0" fluid>
    <v-row class="mt-0">
      <v-progress-linear
        :active="loadingIcon"
        absolute
        color="amber"
        indeterminate
      />
    </v-row>
    <v-row class="mt-6 mb-3">
      <v-col
        v-if="$vuetify.breakpoint.mdAndDown"
        class="pb-0 pl-1 pt-2"
        cols="12"
        md="10"
        sm="9"
      >
        <v-menu close-on-content-click top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="mx-2" icon light>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, index) in btnLinks"
              :key="index"
              :disabled="item.disabled"
              :href="item.href"
              @click="item.action"
            >
              <v-list-item-title
                :class="
                  item.status &&
                  !item.disabled &&
                  'green--text font-weight-medium'
                "
              >
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col
        v-if="$vuetify.breakpoint.lgAndUp"
        class="pb-0 pl-1 pt-2"
        cols="12"
        md="10"
        sm="9"
      >
        <load-detail-button
          :btn-class="vpBtn.class"
          :btn-icon="vpBtn.img"
          :button-text="vpBtn.text"
          :color="vpBtn.color"
          :href="vpBtn.href"
          :target="vpBtn.target"
        />
        <load-detail-button
          :btn-class="rcBtn.class"
          :btn-icon="rcBtn.img"
          :button-text="rcBtn.text"
          :color="rcBtn.color"
          :href="rcBtn.href"
          :target="rcBtn.target"
        />
        <load-detail-button
          :api-call="srcBtn.apiCall"
          :btn-class="srcBtn.class"
          :btn-icon="srcBtn.img"
          :button-text="srcBtn.text"
          :color="srcBtn.color"
          :href="srcBtn.href"
        />
        <v-btn
          class="white--text ma-2"
          color="#1E88E5"
          small
          @click="toggleCarrierMatchListCard"
        >
          <v-icon class="pr-2"> mdi-format-list-bulleted</v-icon>
          Carrier Match List
        </v-btn>
        <load-detail-button
          :btn-class="hlBtn.class"
          :btn-icon="hlBtn.img"
          :button-text="hlBtn.text"
          :color="hlBtn.color"
          :disabled="hlBtn.disabled"
        />
        <v-btn
          :disabled="
            shipmentDetail.loadStatus !== 14 || shipmentDetail.hold === true
          "
          class="white--text ma-2"
          color="green"
          small
          @click="initiateBilling"
        >
          <v-icon class="pr-2"> mdi-cash</v-icon>
          Initiate Billing
        </v-btn>
        <v-btn
          :class="`${
            autoPriceEnabled
              ? 'green white-text'
              : 'grey lighten-2 grey--text text--lighten-1 elevation-0'
          } ma-2`"
          small
          @click="toggleAutoPrice"
        >
          <v-icon class="pr-2"> mdi-currency-usd</v-icon>
          Auto Price
        </v-btn>
      </v-col>
      <v-col class="pb-0 pl-1 pt-0 text-right" cols="12" md="2" sm="3">
        <v-btn
          :class="holdBtn.class"
          :color="shipmentDetail.hold === false ? 'red' : 'grey'"
          :title="holdBtn.text"
          small
          @click="holdLoad()"
        >
          <v-icon small>
            {{ holdBtn.img }}
          </v-icon>
        </v-btn>
        <v-btn
          :class="archiveBtn.class"
          :color="shipmentDetail.archived === false ? 'red' : 'grey'"
          :title="archiveBtn.text"
          small
          @click="archiveLoad()"
        >
          <v-icon small>
            {{ archiveBtn.img }}
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        :cols="carrierMatchListCard && $vuetify.breakpoint.lgAndUp ? 8 : 12"
        class="pt-0"
      >
        <v-row>
          <v-col cols="12" lg="8" md="8" sm="12">
            <shipment-detail />
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12">
            <load-tabs />
          </v-col>
          <v-col
            v-if="carrierMatchListCard && $vuetify.breakpoint.mdAndDown"
            class="pt-0"
            cols="12"
            lg="4"
            md="4"
            sm="12"
            xl="4"
          >
            <carrier-match-list />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0 pt-0" cols="12" lg="4" md="4" sm="12">
            <v-card
              :height="$vuetify.breakpoint.mdAndUp ? '385px' : ''"
              class="mt-0"
              style="overflow: hidden"
            >
              <tender-detail />
            </v-card>
          </v-col>
          <v-col class="pb-0 pt-0" cols="12" lg="8" md="8" sm="12">
            <v-card
              :style="
                $vuetify.breakpoint.lgAndUp ? 'height: 385px' : 'height:auto;'
              "
              class="pa-0 ma-0"
            >
              <v-card-text class="pa-0">
                <v-row class="ma-0">
                  <v-col class="pa-0" cols="12" lg="8" md="8" sm="12">
                    <v-container class="pa-0">
                      <v-row class="ma-0">
                        <v-col
                          class="pa-0"
                          cols="12"
                          md="6"
                          sm="12"
                          style="border-bottom: 1px solid #ccc"
                        >
                          <shipper-info />
                        </v-col>
                        <v-col
                          :style="
                            $vuetify.breakpoint.mdAndUp
                              ? 'border-left:1px solid #CCC; border-bottom:1px solid #CCC;'
                              : 'border-bottom:1px solid #CCC;'
                          "
                          class="pa-0"
                          cols="12"
                          md="6"
                          sm="12"
                        >
                          <consignee-info />
                        </v-col>
                        <v-col
                          :class="$vuetify.breakpoint.md ? 'border-none' : ''"
                          :style="
                            $vuetify.breakpoint.lgAndUp
                              ? ''
                              : 'border-bottom:1px solid #CCC;'
                          "
                          class="pa-0"
                          cols="12"
                          md="6"
                          sm="12"
                        >
                          <carrier-info />
                        </v-col>
                        <v-col
                          :style="
                            $vuetify.breakpoint.mdAndUp
                              ? 'border-left:1px solid #CCC;'
                              : 'border-bottom:1px solid #CCC;'
                          "
                          class="pa-0"
                          cols="12"
                          md="6"
                          sm="12"
                        >
                          <load-stop />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                  <v-col
                    v-if="$vuetify.breakpoint.mdAndUp"
                    class="pa-0"
                    cols="12"
                    md="4"
                    sm="12"
                    style="border-left: 1px solid #ccc; height: 385px"
                  >
                    <v-container class="pa-0" fluid>
                      <v-row class="ma-0">
                        <v-col
                          class="pa-0"
                          cols="12"
                          style="border-bottom: 1px solid #ccc"
                        >
                          <commodity-dimensions />
                        </v-col>
                        <v-col class="py-0" cols="12">
                          <load-date />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                  <v-col v-else class="pa-0" cols="12">
                    <v-container class="pa-0" fluid>
                      <v-row class="ma-0">
                        <v-col
                          class="pt-0"
                          cols="12"
                          style="border-bottom: 1px solid #ccc"
                        >
                          <commodity-dimensions />
                        </v-col>
                        <v-col class="pt-0" cols="12">
                          <load-date />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <load-match-and-quotes :load-id="id" />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
import LoadDetailButton from "@/views/pages/load-detail-components/LoadDetailButton"
import ShipmentDetail from "@/views/pages/load-detail-components/ShipmentDetail"
import LoadTabs from "@/views/pages/load-detail-components/LoadTabs"
import TenderDetail from "@/views/pages/load-detail-components/TenderDetail"
import ShipperInfo from "@/views/pages/load-detail-components/ShipperInfo"
import ConsigneeInfo from "@/views/pages/load-detail-components/ConsigneeInfo"
import CarrierInfo from "@/views/pages/load-detail-components/CarrierInfo"
import LoadDate from "@/views/pages/load-detail-components/LoadDate"
import CommodityDimensions from "@/views/pages/load-detail-components/CommodityDimensions"
import LoadMatchAndQuotes from "@/views/pages/load-detail-components/LoadMatchAndQuotes"
import LoadStop from "@/views/pages/load-detail-components/LoadStop"
import common from "@/utils/common"

export default {
  name: "LoadDetail",
  components: {
    LoadDetailButton,
    ShipmentDetail,
    LoadTabs,
    TenderDetail,
    ShipperInfo,
    ConsigneeInfo,
    CarrierInfo,
    LoadDate,
    CommodityDimensions,
    LoadMatchAndQuotes,
    LoadStop,
  },
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      vpBtn: {
        text: "View/Print BOL",
        img: "mdi-file",
        class: "grey--text text--darken-2 ma-2",
        href: `${process.env.VUE_APP_BASE_URL}/loads/api/bol/${this.id}/`,
        target: "_blank",
        apiCall: false,
        color: "#FFFFFF",
      },
      rcBtn: {
        text: "View/Print Rate Confirmation",
        img: "mdi-file",
        class: "grey--text text--darken-2 ma-2",
        href: `${process.env.VUE_APP_BASE_URL}/loads/api/rate-confirmation/${this.id}/`,
        target: "_blank",
        apiCall: false,
        color: "#FFFFFF",
      },
      srcBtn: {
        text: "Send Rate Confirmation",
        img: "mdi-email",
        class: "grey--text text--darken-2 ma-2",
        href: `${process.env.VUE_APP_BASE_URL}/loads/api/send-rate-confirmation/${this.id}/`,
        target: null,
        apiCall: true,
        color: "#FFFFFF",
      },
      hlBtn: {
        text: "Historical Load",
        img: "mdi-history",
        class: "white--text ma-2",
        href: null,
        target: null,
        apiCall: false,
        color: "#1E88E5",
        disabled: true,
      },
      archiveBtn: {
        text: "Archived",
        img: "mdi-archive",
        class: "white--text mx-2 my-4",
        href: null,
        target: null,
        apiCall: false,
        color: "red",
        disabled: false,
      },
      holdBtn: {
        text: "Hold",
        img: "mdi-lock",
        class: "white--text mx-2 my-4",
        href: null,
        target: null,
        apiCall: false,
        color: "red",
        disabled: false,
      },
      shipper: [],
      consignee: [],
      loadStatusItems: [],
      btnLinks: [
        {
          id: 0,
          title: "View/Print BOL",
          href: `${process.env.VUE_APP_BASE_URL}/loads/api/bol/${this.id}/`,
        },
        {
          id: 1,
          title: "View/Print Rate Confirmation",
          href: `${process.env.VUE_APP_BASE_URL}/loads/api/rate-confirmation/${this.id}/`,
        },
        {
          id: 2,
          title: "Send Rate Confirmation",
          href: `${process.env.VUE_APP_BASE_URL}/loads/api/send-rate-confirmation/${this.id}/`,
        },
        {
          id: 3,
          title: "Carrier Match List",
          action: () => this.toggleCarrierMatchListCard(),
        },
        {
          id: 4,
          title: "Historical Load",
          disabled: true,
        },
        {
          id: 5,
          title: "Initiate Billing",
          action: () => this.initiateBilling(),
        },
        {
          id: 6,
          title: "Auto Price",
          action: () => this.toggleAutoPrice(),
          disabled: false,
        },
      ],
    }
  },
  computed: {
    ...mapGetters("loadDetail", [
      "loadId",
      "autoPriceEnabled",
      "consigneeInfo",
      "shipperInfo",
      "shipmentDetail",
      "loadingIcon",
    ]),
    ...mapGetters("carrierMatching", [
      "carrierMatchListCard",
      "carrierMatchList",
    ]),
    pageTitle() {
      let result = String(this.id)
      if (this.shipmentDetail?.customer?.name) {
        result += ` ${this.abbreviate(this.shipmentDetail.customer.name)}`
      }
      if (this.shipperInfo?.stateProvinceRegion) {
        result += ` ${this.shipperInfo.stateProvinceRegion}`
      }
      if (this.consigneeInfo?.stateProvinceRegion) {
        result += ` — ${this.consigneeInfo.stateProvinceRegion}`
      }
      return result
    },
  },
  watch: {
    id() {
      this.getLoadDetail()
    },
    autoPriceEnabled() {
      this.btnLinks[6].status = this.autoPriceEnabled
    },
  },
  created() {
    this.btnLinks[5] = {
      ...this.btnLinks[5],
      disabled: this.shipmentDetail.loadStatus !== 14,
      status: this.shipmentDetail.loadStatus === 14,
    }
  },
  mounted() {
    this.getLoadDetail()
  },
  methods: {
    initiateBilling() {
      this.$store.commit("loadDetail/setLoadingIcon", true)
      this.$store.dispatch("billing/INVOICE_LOAD", this.id)
    },
    toggleAutoPrice() {
      const payload = {
        id: this.id,
        auto_price_enabled: !this.autoPriceEnabled,
      }
      this.$store.dispatch("loadDetail/PATCH_LOAD_DETAIL", payload)
    },
    async getLoadDetail() {
      this.$store.commit("loadDetail/setLoadingIcon", true)
      this.$store.dispatch("loadDetail/SET_LOAD_ID", this.id)
      const loadPromise = this.$store.dispatch(
        "loadDetail/GET_LOAD_DETAIL",
        this.id
      )
      const smsPromise = this.$store.dispatch("loadDetail/GET_SMS_TEMPLATE")
      const promiseArr = [loadPromise, smsPromise]
      await Promise.all(promiseArr)
      common.methods.setPageTitle(this.pageTitle)
      this.$store.commit("loadDetail/setLoadingIcon", false)
    },
    archiveLoad() {
      const payload = {
        loadId: this.id,
        archived: !this.shipmentDetail.archived,
      }
      this.$store
        .dispatch("loadDetail/POST_ARCHIVED", payload)
        .then((data) => {
          this.shipmentDetail.archived = data
        })
        .catch(() => {})
    },
    toggleCarrierMatchListCard() {
      this.$store.commit("carrierMatching/setMatchDialog", true)
      this.$store.commit("carrierMatching/setDialogLoad", this.shipmentDetail)
    },
    holdLoad() {
      const payload = {
        loadId: this.id,
        hold: !this.shipmentDetail.hold,
      }
      this.$store
        .dispatch("loadDetail/POST_HOLD", payload)
        .then((data) => {
          this.shipmentDetail.hold = data
        })
        .catch(() => {})
    },
    abbreviate(str) {
      return str
        .split(" ")
        .map((word) => word[0].toUpperCase())
        .join("")
    },
  },
}
</script>

<style scoped>
.card-header-text {
  border-radius: 4px 0;
}

.right-bottom-radius {
  border-radius: 0 0 4px 0;
}

.hgt320 {
  height: 320px;
  overflow-y: auto;
}

.border-none {
  border: none !important;
}
</style>
