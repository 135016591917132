<template>
  <div>
    <v-container class="pt-3" fluid>
      <v-row>
        <v-col class="pt-1 pb-0 pr-0" cols="12">
          <v-list-item-subtitle
            class="
              blue--text
              text--lighten-2
              white--text
              box-header
              d-flex
              align-center
            "
          >
            Load stops
            <v-btn
              v-if="!loadEditingDisabled"
              color="grey"
              small
              text
              @click="addStop()"
            >
              Add
            </v-btn>
          </v-list-item-subtitle>
        </v-col>
      </v-row>
    </v-container>
    <v-container :class="$vuetify.breakpoint.mdAndUp ? 'pt-0 hgt150' : 'pt-0'">
      <transition-group name="list" tag="div">
        <template v-for="(item, key) in loadStops">
          <v-row :key="key">
            <v-col class="pb-0 pr-1" cols="12" md="2">
              <b :class="item.stopType == 1 ? 'green--text' : 'red--text'"
                >Stop {{ key + 1 }}:</b
              >
            </v-col>
            <v-col
              class="pb-0 px-1"
              cols="12"
              md="8"
              @click="editLoadStop(item.id)"
            >
              <tooltip :tooltip-content="loadStopDisplay(item)" />
            </v-col>
            <v-col class="pb-0 text-right pr-1" cols="12" md="2">
              <v-icon v-if="key > 0" small @click="moveItemUp(key)">
                mdi-arrow-up-bold
              </v-icon>
              <v-icon
                v-if="key < shipmentDetail.loadStops.length - 1"
                small
                @click="moveItemDown(key)"
              >
                mdi-arrow-down-bold
              </v-icon>
              <v-icon color="red" small @click="confirmDeleteStop(item, key)">
                mdi-delete
              </v-icon>
            </v-col>
          </v-row>
        </template>
      </transition-group>
    </v-container>
    <add-load-stop
      :customer-id="shipmentDetail.customer.id"
      :load-id="shipmentDetail.id"
    />
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Load Stop</v-card-title>
        <v-card-text>
          <p>
            Please confirm that you would like to delete stop
            {{ stopNumber }}.
          </p>
          <p>
            <b>{{ stopName }}</b>
          </p>
          <p>{{ stopDate }}</p>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="grey darken-1" text @click="dialog = false">
            Cancel
          </v-btn>

          <v-btn color="orange darken-1" text @click="deleteLoadStop()">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Tooltip from "@/views/pages/components/Tooltip"
import AddLoadStop from "@/views/pages/load-detail-components/edit-components/AddLoadStop"
import common from "@/utils/common"

export default {
  name: "LoadStop",
  components: {
    AddLoadStop,
    Tooltip,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      isAddNew: false,
      stopName: null,
      loadStops: [],
      stopId: null,
      stopNumber: null,
      stopDate: null,
    }
  },
  computed: {
    ...mapGetters("loadDetail", {
      shipmentDetail: "shipmentDetail",
    }),
    ...mapGetters("generic", {
      agentDetail: "agentDetail",
    }),
    loadEditingDisabled() {
      return common.methods.disableButtonBaseOnLoadStatus(
        this.shipmentDetail,
        this.agentDetail
      )
    },
  },
  watch: {
    shipmentDetail() {
      this.loadStops = this.shipmentDetail.loadStops
    },
  },
  methods: {
    loadStopDisplay(item) {
      return item.locationName + " (" + item.stopDate + ")"
    },
    moveItemUp(index) {
      const payload = {
        id: this.loadStops[index].id,
        direction: 1,
      }
      this.$store
        .dispatch("loadDetail/LOAD_STOP_ORDER_CHANGE", payload)
        .then(() => {
          const reorderStop = [...this.loadStops]
          reorderStop[index] = this.loadStops[index - 1]
          reorderStop[index - 1] = this.loadStops[index]
          this.loadStops = reorderStop
          this.$store.dispatch(
            "loadDetail/GET_LOAD_DETAIL",
            this.shipmentDetail.id
          )
        })
    },
    moveItemDown(index) {
      const payload = {
        id: this.loadStops[index].id,
        direction: 2,
      }
      this.$store
        .dispatch("loadDetail/LOAD_STOP_ORDER_CHANGE", payload)
        .then(() => {
          const reorderStop = [...this.loadStops]
          reorderStop[index] = this.loadStops[index + 1]
          reorderStop[index + 1] = this.loadStops[index]
          this.loadStops = reorderStop
          this.$store.dispatch(
            "loadDetail/GET_LOAD_DETAIL",
            this.shipmentDetail.id
          )
        })
    },
    addStop() {
      this.$store.commit("loadDetail/setAddStopDialog", true)
    },
    confirmDeleteStop(stop, key) {
      this.stopName = stop.locationName
      this.stopId = stop.id
      this.stopDate = stop.stopDate
      this.stopNumber = key + 1
      this.dialog = true
    },
    deleteLoadStop() {
      this.$store
        .dispatch("loadDetail/DELETE_LOAD_STOP", this.stopId)
        .then(() => {
          this.$store.dispatch(
            "loadDetail/GET_LOAD_DETAIL",
            this.shipmentDetail.id
          )
          this.stopName = null
          this.stopId = null
          this.dialog = false
        })
    },
    editLoadStop(stopId) {
      this.$store.commit("loadDetail/setAddStopDialog", true)
      this.$store.dispatch("loadDetail/GET_LOAD_STOP_DETAIL", stopId)
      this.$store.commit("loadDetail/setLoadStopId", stopId)
    },
  },
}
</script>

<style scoped>
.box-header {
  font-size: 14px;
  font-weight: 500;
}

.hgt150 {
  height: 150px;
  overflow-y: auto;
}

.list-enter,
.list-leave-to {
  opacity: 0;
}

.list-enter-active,
.list-leave-active {
  transition: opacity 0.5s ease;
}

.list-move {
  transition: transform 0.5s ease-out;
}
</style>
