<template>
  <v-container class="pa-0">
    <template v-if="mapType == mapTypes.googleMap">
      <GmapMap
        id="map"
        ref="mapRef"
        :center="{ lat: 37.77, lng: -122.447 }"
        :style="
          $vuetify.breakpoint.mdAndUp
            ? 'height: 350px; border-radius: 0px 4px 4px 0px'
            : 'height: 350px; border-radius: 0px 0px 4px 0px'
        "
        :zoom="9"
        map-type-id="terrain"
      />
    </template>
    <template v-else-if="mapType == mapTypes.hereMap">
      <here-map
        :center="{ lat: 37.77, lng: -122.447 }"
        :destination="destination"
        :origin="origin"
        :way-points="loadStops"
        height="350px"
        width="100%"
      />
    </template>
  </v-container>
</template>
<script>
import { gmapApi } from "vue2-google-maps"
import { mapGetters } from "vuex"
import HereMap from "@/views/pages/components/HereMap"
import common from "@/utils/common"

export default {
  name: "ShipmentMap",
  components: {
    HereMap,
  },
  data() {
    return {
      mapLoad: false,
      origin: "",
      destination: "",
      loadStops: [],
      mapType: 0,
      mapTypes: common.data.mapType,
    }
  },
  computed: {
    google: gmapApi,
    ...mapGetters("loadDetail", {
      shipperInfo: "shipperInfo",
      consigneeInfo: "consigneeInfo",
      reloadMap: "reloadMap",
      shipmentDetail: "shipmentDetail",
    }),
    ...mapGetters("generic", {
      agentDetail: "agentDetail",
    }),
  },
  watch: {
    shipperInfo() {
      if (this.reloadMap === true && this.mapLoad === false) {
        this.mapLoad = true
        if (this.mapType === this.mapTypes.googleMap) {
          this.initMap()
        }
      }
      if (this.mapType === this.mapTypes.hereMap) {
        this.origin =
          this.shipperInfo.latitude + "," + this.shipperInfo.longitude
      }
      return this.$store.state.shipperInfo
    },
    consigneeInfo() {
      if (this.reloadMap === true && this.mapLoad === false) {
        this.mapLoad = true
        if (this.mapType === this.mapTypes.googleMap) {
          this.initMap()
        }
      }
      if (this.mapType === this.mapTypes.hereMap) {
        this.destination =
          this.consigneeInfo.latitude + "," + this.consigneeInfo.longitude
      }
      return this.$store.state.consigneeInfo
    },
    shipmentDetail() {
      if (this.shipmentDetail.loadStops.length > 0) {
        this.loadStops = []
        const stops = this.shipmentDetail.loadStops
        if (this.mapType === this.mapTypes.hereMap) {
          for (let i = 0; i < stops.length; i++) {
            this.loadStops.push(
              stops[i].locationLatitude + "," + stops[i].locationLongitude
            )
          }
        } else {
          for (let i = 0; i < stops.length; i++) {
            this.loadStops.push({
              location:
                stops[i].locationLatitude + "," + stops[i].locationLongitude,
              stopover: true,
            })
          }
          this.initMap()
        }
      } else {
        this.loadStops = null
      }
    },
  },
  mounted() {
    this.$store.dispatch("generic/GET_AGENT_DETAIL").then((data) => {
      this.mapType = data.mapType
      if (this.mapType === this.mapTypes.googleMap) {
        this.initMap()
      }
    })
  },
  methods: {
    initMap() {
      if (this.google) {
        const directionsRenderer = new this.google.maps.DirectionsRenderer()
        const directionsService = new this.google.maps.DirectionsService()
        const mapNew = new this.google.maps.Map(
          document.getElementById("map"),
          {
            zoom: 14,
            center: {
              lat: 37.77,
              lng: -122.447,
            },
          }
        )
        directionsRenderer.setMap(mapNew)
        this.calculateAndDisplayRoute(directionsService, directionsRenderer)
        this.mapLoad = false
        this.$store.commit("loadDetail/setReloadMap", false)
      }
    },
    calculateAndDisplayRoute(directionsService, directionsRenderer) {
      const waypoints = this.loadStops
      directionsService.route(
        {
          origin: {
            lat: this.shipperInfo.latitude,
            lng: this.shipperInfo.longitude,
          },
          destination: {
            lat: this.consigneeInfo.latitude,
            lng: this.consigneeInfo.longitude,
          },
          travelMode: "DRIVING",
          waypoints: waypoints,
        },
        (response, status) => {
          if (status === "OK") {
            directionsRenderer.setDirections(response)
          } else {
            window.alert("Directions request failed due to " + status)
          }
        }
      )
    },
  },
}
</script>
