<template>
  <div>
    <v-stepper
      v-model="currentStep"
      class="elevation-0 pl-0 pr-0 pb-0"
      alt-labels
    >
      <v-stepper-header>
        <v-stepper-step
          :complete="currentStep > 0"
          step="1"
          class="ml-n15 pb-0 pt-1"
        >
          <p class="grey--text text--darken-1 mb-1 text-center">Plan</p>
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="currentStep > 1" step="2" class="pb-0 pt-1">
          <p class="grey--text text--darken-1 mb-1 text-center">Assigned</p>
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="currentStep > 2" step="3" class="pb-0 pt-1">
          <p class="grey--text text--darken-1 mb-1 text-center">
            Pickup <br />
            <span class="font-weight-bold grey--text text--darken-4">
              <render-date :date="shipmentDetail.pickupDate" />
            </span>
          </p>
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="currentStep > 3" step="4" class="pb-0 pt-1">
          <p class="grey--text text--darken-1 mb-1 text-center">
            Delivery<br />
            <span class="font-weight-bold grey--text text--darken-4"
              ><render-date :date="shipmentDetail.deliveryDate"
            /></span>
          </p>
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          color="green"
          :complete="currentStep > 4"
          step="5"
          :class="currentStep === 6 ? 'pb-0 pt-1' : 'mr-n15 pb-0 pt-1'"
        >
          <p class="grey--text text--darken-1 mb-1 text-center">Invoiced</p>
        </v-stepper-step>
        <v-divider v-if="currentStep === 6" />
        <v-stepper-step
          v-if="currentStep === 6"
          color="red"
          :complete="currentStep > 5"
          step="6"
          class="mr-n15 pb-0 pt-1"
        >
          <p class="grey--text text--darken-1 mb-1 text-center">Claim</p>
        </v-stepper-step>
      </v-stepper-header>
    </v-stepper>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import RenderDate from "@/views/pages/components/RenderDate"
export default {
  name: "ProgressStepper",
  components: {
    RenderDate,
  },
  data() {
    return {
      enumLoadStatus: {
        plan: 1,
        available: 2,
        booked: 3,
        confirm: 4,
        picked: 5,
        delivered: 6,
        claim: 7,
        invoiced: 8,
        paid: 9,
        pickOnTrack: 10,
        pickOffTrack: 11,
        deliveryOnTrack: 12,
        deliveryOffTrack: 13,
        readyToBill: 14,
        needsAppt: 15,
        loading: 16,
        unloading: 17,
        received: 18,
      },
    }
  },
  computed: {
    ...mapGetters("loadDetail", ["shipmentDetail"]),
    currentStep() {
      const loadStatus = this.shipmentDetail.loadStatus
      if (!this.isNumeric(loadStatus)) {
        switch (loadStatus) {
          case "available":
          case "plan":
            return 1
          case "confirmed":
          case "booked":
            return 2
          case "picked":
            return 3
          case "delivered":
            return 4
          case "invoiced":
          case "paid":
            return 5
          case "claim":
          case "received":
            return 6
          default:
            return 0
        }
      } else {
        switch (loadStatus) {
          case this.enumLoadStatus.available:
          case this.enumLoadStatus.plan:
          case this.enumLoadStatus.needsAppt:
            return 1
          case this.enumLoadStatus.confirm:
          case this.enumLoadStatus.booked:
          case this.enumLoadStatus.pickOnTrack:
          case this.enumLoadStatus.pickOffTrack:
          case this.enumLoadStatus.loading:
            return 2
          case this.enumLoadStatus.picked:
            return 3
          case this.enumLoadStatus.delivered:
          case this.enumLoadStatus.deliveryOnTrack:
          case this.enumLoadStatus.deliveryOffTrack:
          case this.enumLoadStatus.unloading:
          case this.enumLoadStatus.readyToBill:
            return 4
          case this.enumLoadStatus.invoiced:
          case this.enumLoadStatus.received:
          case this.enumLoadStatus.paid:
            return 5
          case this.enumLoadStatus.claim:
            return 6
          default:
            return 0
        }
      }
    },
  },
  methods: {
    isNumeric: function (n) {
      return !isNaN(parseFloat(n)) && isFinite(n)
    },
  },
}
</script>
