<template>
  <v-dialog v-model="addStopDialog" persistent width="600">
    <v-card>
      <v-card-title style="background-color: #1e88e5" class="pt-0 pl-0">
        <v-row class="align-center">
          <v-col cols="12" class="mt-2">
            <span class="text-h5 white--text">{{ titleText }}</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-form ref="load-stop-form" v-model="valid">
          <v-row class="align-center pt-8 pb-4">
            <v-col cols="12" md="6" sm="6">
              <v-icon color="#1E88E5" class="float-left mr-2 mt-2">
                mdi-map-marker
              </v-icon>
              <simple-location-auto-complete
                :customer-id="customerId"
                @event="selectedLocation"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                v-model="currentLocation.address"
                type="text"
                label="Address"
                disabled
                class="mt-n2"
              >
                <template v-slot:prepend>
                  <v-icon color="#1E88E5"> mdi-assistant </v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                v-model="currentLocation.city"
                type="text"
                label="City"
                disabled
                class="mt-n2"
              >
                <template v-slot:prepend>
                  <v-icon color="#1E88E5"> mdi-city </v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                v-model="currentLocation.stateProvinceRegion"
                type="text"
                label="State"
                disabled
                class="mt-n2"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                v-model="currentLocation.postalCode"
                type="text"
                label="Postal Code"
                disabled
                class="mt-n2"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-menu
                v-model="stopDateCalendar"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="stopDate"
                    label="Stop Date"
                    :rules="[rules.required]"
                    persistent-hint
                    readonly
                    class="pt-0"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:prepend>
                      <v-icon color="#1E88E5"> mdi-calendar </v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="stopDate"
                  no-title
                  @input="stopDateCalendar = false"
                />
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                v-model="earlyTime"
                v-mask="'##:##'"
                class="pt-0"
                label="Early Time"
                :rules="[rules.time]"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                v-model="lateTime"
                v-mask="'##:##'"
                class="pt-0"
                label="Late Time"
                :rules="[rules.time]"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                v-model="phone"
                type="text"
                label="Phone"
                :rules="[rules.phone]"
              >
                <template v-slot:prepend>
                  <v-icon color="#1E88E5"> mdi-phone </v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                v-model="contactName"
                type="text"
                label="Contact Name"
                :rules="[rules.alphaNumeric]"
              >
                <template v-slot:prepend>
                  <v-icon color="#1E88E5"> mdi-account </v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field v-model="notes" type="text" label="Notes">
                <template v-slot:prepend>
                  <v-icon color="#1E88E5"> mdi-note </v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-select
                v-model="stopType"
                :items="stopTypes"
                label="Type"
                class="mt-1"
                item-text="text"
                item-value="id"
                :rules="[rules.required]"
              >
                <template v-slot:prepend>
                  <v-icon color="#1E88E5"> mdi-stop </v-icon>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer />
        <v-btn color="grey darken-1" text @click="closeDialog()"> Close </v-btn>
        <v-btn color="orange darken-1" text @click="submitAddStop()">
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import SimpleLocationAutoComplete from "@/views/pages/components/SimpleLocationAutoComplete"
import validation from "@/utils/form-validation"
export default {
  name: "AddLoadStop",
  components: {
    SimpleLocationAutoComplete,
  },
  props: {
    loadId: {
      type: [String, Number],
      default: null,
    },
    customerId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      valid: false,
      titleText: "Add Load Stop",
      stopId: null,
      stopDateCalendar: false,
      stopDate: null,
      earlyMenu: false,
      earlyTime: null,
      lateMenu: false,
      lateTime: null,
      rules: validation.rules,
      phone: null,
      contactName: null,
      notes: null,
      stopType: null,
      location: null,
      stopTypes: [
        {
          id: 1,
          text: "Pickup",
        },
        {
          id: 2,
          text: "Drop",
        },
      ],
    }
  },
  computed: {
    ...mapGetters("loadDetail", {
      addStopDialog: "addStopDialog",
      loadStopDetail: "loadStopDetail",
      loadStopId: "loadStopId",
    }),
    ...mapGetters("location", {
      currentLocation: "currentLocation",
    }),
  },
  watch: {
    loadStopDetail() {
      if (this.loadStopDetail) {
        this.titleText = "Load Stop " + (this.loadStopDetail.order + 1)
        this.stopId = this.loadStopDetail.id
        this.stopDate = this.loadStopDetail.stopDate
        this.earlyTime = this.loadStopDetail.stopEarlyTime
        this.lateTime = this.loadStopDetail.stopLateTime
        this.phone = this.loadStopDetail.phone
        this.contactName = this.loadStopDetail.contactName
        this.notes = this.loadStopDetail.notes
        this.stopType = this.loadStopDetail.stopType
        this.location = {
          id: this.loadStopDetail.locationId,
          name: this.loadStopDetail.locationName,
        }
        this.$store.commit("generic/setSelectedLocation", this.location)
        this.getLocationDetail(this.location.id)
      }
    },
    loadStopId() {
      if (!this.loadStopId) {
        this.titleText = "Add Load Stop"
        this.stopId = null
        this.stopDate = null
        this.earlyTime = null
        this.lateTime = null
        this.phone = null
        this.contactName = null
        this.notes = null
        this.stopType = null
        this.location = null
      }
    },
  },
  methods: {
    submitAddStop() {
      if (this.location) {
        if (this.$refs["load-stop-form"].validate()) {
          if (this.loadStopId) {
            const payload = {
              id: this.stopId,
              load: this.loadId,
              location: this.location.id,
              stop_date: this.stopDate,
              stop_early_time: this.earlyTime,
              stop_late_time: this.lateTime,
              phone: this.phone,
              contact_name: this.contactName,
              notes: this.notes,
              stop_type: this.stopType,
            }
            this.$store
              .dispatch("loadDetail/UPDATE_LOAD_STOP", payload)
              .then(() => {
                this.$store.dispatch("loadDetail/GET_LOAD_DETAIL", this.loadId)
                this.$store.commit("loadDetail/setAddStopDialog", false)
                this.$refs["load-stop-form"].reset()
                this.$refs["load-stop-form"].resetValidation()
              })
          } else {
            const payload = {
              load: this.loadId,
              location: this.location.id,
              stop_date: this.stopDate,
              stop_early_time: this.earlyTime,
              stop_late_time: this.lateTime,
              phone: this.phone,
              contact_name: this.contactName,
              notes: this.notes,
              stop_type: this.stopType,
            }
            this.$store
              .dispatch("loadDetail/ADD_NEW_LOAD_STOP", payload)
              .then(() => {
                this.$store.dispatch("loadDetail/GET_LOAD_DETAIL", this.loadId)
                this.$store.commit("loadDetail/setAddStopDialog", false)
                this.$refs["load-stop-form"].reset()
                this.$refs["load-stop-form"].resetValidation()
              })
          }
        }
      } else {
        const snackbar = {
          status: true,
          text: "Location is required.",
        }
        this.$store.commit("main/SET_SNACKBAR", snackbar)
      }
    },
    closeDialog() {
      this.$store.commit("loadDetail/setAddStopDialog", false)
      this.$store.commit("loadDetail/setLoadStopId", null)
      this.$refs["load-stop-form"].reset()
      this.$refs["load-stop-form"].resetValidation()
    },
    selectedLocation(value) {
      if (value.location) {
        this.getLocationDetail(value.location.id)
        this.location = value.location
      } else {
        this.location = null
      }
    },
    getLocationDetail(locationId) {
      const payload = {
        locationId: locationId,
      }
      this.$store.dispatch("location/GET_LOCATION", payload)
    },
  },
}
</script>
