<template>
  <div class="matches-detail">
    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.smAndDown ? false : true"
      content-class="match-detail-dialog"
      persistent
      transition="slide-x-reverse-transition"
    >
      <v-toolbar dark style="background-color: #1e88e5">
        <v-btn dark icon @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
          <p />
        </v-btn>
        <v-toolbar-title v-if="dialogLoad">
          <b> {{ dialogLoad.customer.name }} | {{ dialogLoad.id }}</b>
          - {{ dialogLoad.equipmentType }}
        </v-toolbar-title>
      </v-toolbar>
      <v-container v-if="dialogLoad" class="pb-0" fluid>
        <v-row style="background-color: #ffffff">
          <v-col class="pb-0 pt-0" cols="12" md="6">
            <v-list-item-title class="text-h5 mb-1">
              <v-icon color="green" x-large> mdi-map-marker</v-icon>
              Origin
            </v-list-item-title>
            <p class="mb-0">
              {{ formatLocation(origin.city, origin.stateProvinceRegion) }}
              | {{ formatDate(dialogLoad.pickupDate) }}
            </p>
            <p class="mb-0">
              {{ loadTimeInfo.shipperEarlyTime }} -
              {{ loadTimeInfo.shipperLateTime }}
            </p>
          </v-col>
          <v-col class="pb-0 pt-0" cols="12" md="6">
            <v-list-item-title class="text-h5 mb-1">
              <v-icon color="red" x-large> mdi-map-marker</v-icon>
              Destination
            </v-list-item-title>
            <p class="mb-0">
              {{
                formatLocation(
                  destination.city,
                  destination.stateProvinceRegion
                )
              }}
              | {{ formatDate(dialogLoad.deliveryDate) }}
            </p>
            <p class="mb-0">
              {{ loadTimeInfo.consigneeEarlyTime }} -
              {{ loadTimeInfo.consigneeLateTime }}
            </p>
          </v-col>
        </v-row>
        <v-row style="background-color: #ffffff">
          <v-col class="pb-0 pt-2" cols="4" md="2">
            <v-list-item-title class="text-h4 mb-1">
              <b>Miles</b>
            </v-list-item-title>
            <p class="mb-1">
              {{ commodityInfo.miles }}
            </p>
          </v-col>
          <v-col class="pt-2 pb-0" cols="4" md="2">
            <v-list-item-title class="text-h4 mb-1">
              <b>Height</b>
            </v-list-item-title>
            <p class="mb-1">
              {{ commodityInfo.loadHeight }}
            </p>
          </v-col>
          <v-col class="pt-2 pb-0" cols="4" md="2">
            <v-list-item-title class="text-h4 mb-1">
              <b>Weight</b>
            </v-list-item-title>
            <p class="mb-1">
              {{ commodityInfo.loadWeight }}
            </p>
          </v-col>
          <v-col class="pt-2 pb-0" cols="4" md="2">
            <v-list-item-title class="text-h4 mb-1">
              <b>Length</b>
            </v-list-item-title>
            <p class="mb-1">
              {{ commodityInfo.loadLength }}
            </p>
          </v-col>
          <v-col class="pt-2 pb-0" cols="4" md="2">
            <v-list-item-title class="text-h4 mb-1">
              <b>Width</b>
            </v-list-item-title>
            <p class="mb-1">
              {{ commodityInfo.loadWidth }}
            </p>
          </v-col>
          <v-col class="pt-2 pb-0" cols="4" md="2">
            <v-list-item-title class="text-h4 mb-1">
              <b>Commodity</b>
            </v-list-item-title>
            <p class="mb-1">
              {{ commodityInfo.commodity }}
            </p>
          </v-col>
        </v-row>
        <v-row style="border-top: solid 1px #ddd; height: 100%">
          <v-container>
            <v-expansion-panels v-model="panel" multiple>
              <v-expansion-panel>
                <v-expansion-panel-header> Quotes</v-expansion-panel-header>
                <v-expansion-panel-content
                  style="max-height: 300px; overflow-y: auto"
                >
                  <v-data-table
                    :headers="headers"
                    :items="quoteList"
                    :items-per-page="pageSize"
                    calculate-widths
                    fixed-header
                    hide-default-footer
                  >
                    <template v-slot:item.name="{ item }">
                      <router-link
                        v-if="item.carrierCompany"
                        :to="{
                          name: 'Carrier Detail',
                          params: {
                            id: item.carrierCompany,
                          },
                        }"
                        class="name-link"
                        target="_blank"
                      >
                        <span>{{ item.name }}</span>
                      </router-link>
                      <span v-else>
                        {{ item.name }}
                        <v-icon color="red" x-small>mdi-bus-alert</v-icon>
                      </span>
                    </template>
                    <template v-slot:footer>
                      <v-page
                        :page-size-menu="[10, 20, 50, 100]"
                        :total-row="totalRecord"
                        class="pt-3 pr-3 pb-3"
                        @page-change="readDataFromAPI"
                      />
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Carrier Match List
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  style="max-height: 300px; overflow-y: auto"
                >
                  <carrier-match-list />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
        </v-row>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import common from "@/utils/common"
import moment from "moment"
import CarrierMatchList from "@/views/pages/load-detail-components/CarrierMatchList"

export default {
  name: "LoadQuotes",
  components: {
    CarrierMatchList,
  },
  props: {
    loadId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      panel: [0, 1],
      headers: [
        {
          text: "Date",
          value: "createdAt",
          sortable: false,
        },
        {
          text: "Name",
          value: "name",
          sortable: false,
          width: "20%",
        },
        {
          text: "Email",
          value: "email",
          sortable: false,
        },
        {
          text: "Phone",
          value: "phone",
          sortable: false,
        },
        {
          text: "Dot #",
          value: "dotNumber",
          sortable: false,
        },
        {
          text: "MC #",
          value: "mcNumber",
          sortable: false,
        },
        {
          text: "Bid $",
          value: "carrierBid",
          sortable: false,
        },
      ],
      quoteList: [],
      queryParams: {},
      totalRecord: 0,
      pageSize: 10,
      numberOfPages: 0,
      page: 1,
      origin: null,
      destination: null,
      commodity: null,
      loadTimeInfo: null,
    }
  },
  computed: {
    ...mapGetters("carrierMatching", {
      dialog: "matchDialog",
      dialogLoad: "dialogLoad",
      quoteListByLoad: "quoteListByLoad",
    }),
    ...mapGetters("loadDetail", {
      shipmentDetail: "shipmentDetail",
      shipperInfo: "shipperInfo",
      consigneeInfo: "consigneeInfo",
      commodityInfo: "commodityInfo",
      tenderDetails: "tenderDetails",
      loadInfo: "loadInfo",
    }),
  },
  watch: {
    quoteListByLoad() {
      this.totalRecord = this.quoteListByLoad.count
      this.quoteList = this.quoteListByLoad.results
      this.numberOfPages = Math.ceil(this.totalRecord / this.pageSize)
    },
    shipperInfo() {
      this.origin = this.shipperInfo
    },
    consigneeInfo() {
      this.destination = this.consigneeInfo
    },
    commodityInfo() {
      this.commodity = this.commodityInfo
    },
    loadInfo() {
      this.loadTimeInfo = this.loadInfo
    },
    dialogLoad() {
      this.quoteList = []
      this.getQuoteList()
    },
  },
  methods: {
    formatLocation: common.methods.formatLocation,
    formatDate(date) {
      return moment(date).format("dddd, MMMM Do, YYYY")
    },
    getQuoteList() {
      this.queryParams = {}
      this.queryParams.loadId = this.loadId
      this.queryParams.limit = 10
      this.queryParams.offset = 0
      this.$store.dispatch(
        "carrierMatching/GET_QUOTES_BY_LOAD",
        this.queryParams
      )
    },
    closeDialog() {
      this.$store.commit("carrierMatching/setMatchDialog", false)
    },
    readDataFromAPI(value) {
      if (this.quoteList.length > 0) {
        this.page = value.pageNumber
        this.pageSize = value.pageSize
        let pageNumber = this.pageSize * this.page - this.pageSize
        if (pageNumber < 0) {
          pageNumber = 0
        }
        this.queryParams.offset = pageNumber
        this.queryParams.limit = this.pageSize
        this.$store.dispatch(
          "carrierMatching/GET_QUOTES_BY_LOAD",
          this.queryParams
        )
      }
    },
  },
}
</script>

<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}

.v-data-table /deep/ .v-data-table__wrapper /deep/ .v-data-table-header th {
  background-color: #f6f6f6;
}

.load-number {
  cursor: pointer;
  text-decoration: none;
}

.load-number:hover {
  cursor: pointer;
  text-decoration: underline;
}

.v-btn:not(.v-btn--round).v-size--small {
  font-size: 12px;
}

.v-expansion-panel-header {
  padding: 8px 12px !important;
}

.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 40px;
}

.name-link {
  text-decoration: none;
  cursor: pointer;
  color: #000000;
}

.name-link:hover {
  text-decoration: none;
}
</style>
<style>
.v-dialog--fullscreen {
  border-radius: 0;
  margin: 0;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  left: 50% !important;
  width: 50% !important;
}

.match-detail-dialog {
  background-color: #f8f9fa;
}

.v-expansion-panel-content__wrap {
  padding: 0 8px 16px !important;
}

.dialog-fullscreen {
  left: 0% !important;
  width: 100% !important;
}

select {
  -webkit-appearance: auto;
  -moz-appearance: auto;
}

.v-pagination {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
</style>
