<template>
  <div>
    <v-row>
      <v-col class="pa-0 py-2" cols="12">
        <v-list-item-subtitle
          class="blue--text text--lighten-2 pa-1 white--text box-header"
        >
          Load dates
        </v-list-item-subtitle>
      </v-col>
    </v-row>
    <v-row>
      <v-container
        :class="$vuetify.breakpoint.mdAndUp ? 'pt-0 hgt150' : 'pt-0'"
        class="pa-0"
      >
        <v-row>
          <v-col class="pt-0 pl-1" cols="6" lg="4" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Due Date Early</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <tooltip :tooltip-content="loadDates.dueDateEarly" />
            </v-list-item-subtitle>
          </v-col>
          <v-col class="pt-0 pl-1" cols="6" lg="4" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Due Date Late</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <tooltip :tooltip-content="loadDates.dueDateLate" />
            </v-list-item-subtitle>
          </v-col>
          <v-col class="pt-0 pl-1" cols="6" lg="4" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Created</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <tooltip :tooltip-content="loadDates.dateCreated" />
            </v-list-item-subtitle>
          </v-col>
          <v-col class="pt-0 pl-1" cols="6" lg="4" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Booked</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <tooltip :tooltip-content="loadDates.bookedDate" />
            </v-list-item-subtitle>
          </v-col>
          <v-col class="pt-0 pl-1" cols="6" lg="4" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Confirmed</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <tooltip :tooltip-content="loadDates.confirmedDate" />
            </v-list-item-subtitle>
          </v-col>
          <v-col class="pt-0 pl-1" cols="6" lg="4" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Assigned</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <tooltip :tooltip-content="loadDates.assignedAt" />
            </v-list-item-subtitle>
          </v-col>
          <v-col class="pt-0 pl-1" cols="6" lg="4" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Picked</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <tooltip :tooltip-content="loadDates.pickedDate" />
            </v-list-item-subtitle>
          </v-col>
          <v-col class="pt-0 pl-1" cols="6" lg="4" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Delivered</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <tooltip :tooltip-content="loadDates.deliveredDate" />
            </v-list-item-subtitle>
          </v-col>
          <v-col class="pt-0 pl-1" cols="6" lg="4" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Invoiced</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <tooltip :tooltip-content="loadDates.invoicedDate" />
            </v-list-item-subtitle>
          </v-col>
          <v-col class="pt-0 pl-1" cols="6" lg="4" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Paid</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <tooltip :tooltip-content="loadDates.paidDate" />
            </v-list-item-subtitle>
          </v-col>
          <v-col class="pt-0 pl-1" cols="6" lg="4" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Updated</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <tooltip :tooltip-content="loadDates.updatedAt" />
            </v-list-item-subtitle>
          </v-col>
          <v-col class="pt-0 pl-1" cols="6" lg="4" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Archived</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <tooltip :tooltip-content="loadDates.archivedAt" />
            </v-list-item-subtitle>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import Tooltip from "@/views/pages/components/Tooltip"

export default {
  name: "LoadDate",
  components: {
    Tooltip,
  },
  computed: {
    ...mapGetters("loadDetail", {
      loadDates: "loadDates",
    }),
  },
}
</script>
<style scoped>
.box-header {
  font-size: 14px;
  font-weight: 500;
}

.hgt150 {
  height: 150px;
  overflow-y: auto;
}

.row {
  margin: 0;
}
</style>
