<template>
  <v-data-table
    :headers="headers"
    :items="carrierMatchList"
    :items-per-page="pageSize"
    hide-default-footer
    calculate-widths
    fixed-header
  >
    <template v-slot:item.matchType="{ item }">
      <v-icon title="Historical load">
        {{ matchTypeIcon(item.matchType) }}
      </v-icon>
    </template>
    <template v-slot:item.dotNumber="{ item }">
      <span class="grey--text text--darken-2">MC:</span> {{ item.mcNumber
      }}<br />
      <span class="grey--text text--darken-2">DOT:</span>
      {{ item.dotNumber }}
    </template>
    <template v-slot:item.phone="{ item }">
      <span class="grey--text text--darken-2">Phone:</span>
      <a :href="`tel:${item.phone}`">{{ item.phone }}</a> <br />
      <span class="grey--text text--darken-2">Email:</span>
      <a
        target="_blank"
        :href="`https://mail.google.com/mail/?view=cm&fs=1&to=${item.email}`"
        >{{ item.email }}</a
      >
    </template>
    <template v-slot:item.name="{ item }">
      <router-link
        target="_blank"
        style="color: #212121; text-decoration: none"
        :to="{ name: 'Carrier Detail', params: { id: item.id } }"
      >
        {{ item.name }}
      </router-link>
    </template>
    <template v-slot:footer>
      <v-page
        :total-row="totalRecord"
        :page-size-menu="[10, 20, 50, 100]"
        class="pt-3 pr-3 pb-3"
        @page-change="readDataFromAPI"
      />
    </template>
  </v-data-table>
</template>
<script>
import { mapGetters } from "vuex"
export default {
  name: "LoadDetailCarrierMatchList",
  data() {
    return {
      headers: [
        {
          text: "Type",
          align: "start",
          sortable: false,
          value: "matchType",
        },
        {
          text: "Carrier Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Authority",
          value: "dotNumber",
          align: "start",
          sortable: false,
        },
        {
          text: "Contact",
          value: "phone",
          align: "start",
          sortable: false,
        },
        {
          text: "Load Count",
          value: "loadCount",
          align: "start",
          sortable: false,
        },
        {
          text: "RPM",
          value: "averageRpm",
          align: "start",
          sortable: false,
        },
      ],
      totalRecord: 0,
      carrierMatchList: [],
      pageSize: 10,
      numberOfPages: 0,
      options: {},
      currentPage: 1,
      page: 1,
    }
  },
  computed: {
    ...mapGetters("loadDetail", {
      shipperInfo: "shipperInfo",
      consigneeInfo: "consigneeInfo",
      shipmentDetail: "shipmentDetail",
    }),
    ...mapGetters("carrierMatching", {
      carrierMatches: "carrierMatches",
    }),
  },
  watch: {
    carrierMatches() {
      this.totalRecord = this.carrierMatches.count
      this.numberOfPages = Math.ceil(this.carrierMatches.count / this.pageSize)
      this.carrierMatchList = this.carrierMatches.carrierMatchList
    },
  },
  mounted() {
    const payload = {
      shipper: this.shipperInfo.id,
      consignee: this.consigneeInfo.id,
      equipmentType: this.shipmentDetail.equipmentType,
      offset: 0,
    }
    this.$store.dispatch("carrierMatching/GET_CARRIER_MATCH_LIST", payload)
  },
  methods: {
    matchTypeIcon: function (val) {
      if (val === 1) {
        return "mdi-history"
      } else if (val === 2) {
        return "mdi-account"
      } else if (val === 3) {
        return "mdi-truck"
      } else if (val === 4) {
        return "mdi-truck-delivery"
      } else {
        return "mdi-road-variant"
      }
    },
    readDataFromAPI(value) {
      if (this.carrierMatchList.length > 0) {
        this.page = value.pageNumber
        this.pageSize = value.pageSize
        let pageNumber = this.pageSize * this.page - this.pageSize
        if (pageNumber < 0) {
          pageNumber = 0
        }
        const payload = {
          shipper: this.shipperInfo.id,
          consignee: this.consigneeInfo.id,
          equipmentType: this.shipmentDetail.equipmentType,
          offset: pageNumber,
        }
        this.$store.dispatch("carrierMatching/GET_CARRIER_MATCH_LIST", payload)
      }
    },
  },
}
</script>
<style>
.v-data-table /deep/ .v-data-table__wrapper /deep/ .v-data-table-header th {
  background-color: #f6f6f6;
}
.v-data-table /deep/ .v-data-table__wrapper {
  max-height: 685px;
  overflow-y: auto;
}
</style>
