<template>
  <v-card :height="$vuetify.breakpoint.mdAndUp ? '350' : 'auto'" class="mt-0">
    <v-progress-linear
      v-if="uploadProgress > 0"
      v-model="uploadProgress"
      height="25"
    >
      <strong>{{ Math.ceil(uploadProgress) }}%</strong>
    </v-progress-linear>

    <v-tabs v-model="currentTab" fixed-tab grow @change="changeTab">
      <v-tab v-for="tab in tabs" :key="tab.href" :href="`#tab-${tab.href}`">
        {{ tab.title }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentTab">
      <v-tab-item
        v-for="tab in tabs"
        :key="tab.href"
        :style="$vuetify.breakpoint.mdAndUp ? 'height:218px' : 'height:285px'"
        :value="`tab-${tab.href}`"
        class="pt-2"
        style="overflow-y: auto"
        @scroll="onScroll"
      >
        <v-card class="mt-1" flat>
          <!-- notes, file upload and sms tab panes -->
          <v-card-text v-if="!isAuditLogTabActive">
            <div v-for="(content, index) in tab.content" :key="index">
              <p class="grey--text text-caption mb-2">
                {{ formatDate(content.date) }} |
                {{ content.userFullName }}
              </p>
              <p v-if="isFileUpload" class="grey--text text-caption mb-2">
                <b>Type:</b> {{ content.documentTypeDisplay }}
              </p>
              <p
                class="text-body-1 mb-2"
                v-html="content.text.replace(/(?:\r\n|\r|\n)/g, '<br>')"
              />
              <v-card-actions class="pl-0 pt-0">
                <v-btn
                  v-if="footerBtn1.length > 0"
                  :color="footerBtn1 === 'Send' ? 'success' : 'normal'"
                  small
                  text
                  @click="editAndSendEvent(tab.href, content)"
                >
                  {{ footerBtn1 }}
                </v-btn>
                <v-btn
                  v-if="footerBtn2.length > 0"
                  color="error"
                  small
                  text
                  @click="deleteItemEvent(tab.href, content)"
                >
                  {{ footerBtn2 }}
                </v-btn>
              </v-card-actions>
              <v-divider class="pb-2" />
            </div>
          </v-card-text>
          <!-- / notes, file upload and sms tab panes -->

          <!-- audit log tab pane -->
          <div v-if="isAuditLogTabActive" class="px-4">
            <div
              v-if="!loadingIcon && !auditLog.results.length"
              class="text-center"
            >
              There are no changes yet.
            </div>
            <div v-for="item in auditLog.results" :key="item.timeStamp">
              <p class="grey--text mb-2">
                {{ formatDate(item.timeStamp) }} |
                {{ item.username }}
                {{ item.changeType.toLowerCase() }}
              </p>
              <div v-for="change in item.changes" :key="change.field">
                <p class="text-body-1 mb-2">
                  <span class="font-weight-bold">{{
                    change.field.replaceAll("_", " ")
                  }}</span>
                  from
                  <span class="font-weight-bold">{{ change.old }}</span>
                  to
                  <span class="font-weight-bold">{{ change.new }}</span>
                </p>
              </div>
              <v-divider class="pb-2" />
            </div>
          </div>
          <!-- / audit log tab pane -->
        </v-card>
      </v-tab-item>

      <!-- tab footer -->
      <div
        class="rounded grey lighten-4"
        style="min-height: 82px; border-radius: 0 0 4px 4px; margin-top: 2px"
      >
        <v-row class="ma-0">
          <v-col
            :cols="$vuetify.breakpoint.lgAndUp ? '9' : '8'"
            class="mt-3 pa-0"
          >
            <v-row>
              <!-- notes -->
              <v-form
                v-if="isNotes"
                ref="note-form"
                v-model="noteFormValid"
                class="col-md-12"
                lazy-validation
              >
                <v-textarea
                  v-model="txtnote"
                  :rules="noteValidations"
                  class="pl-5 pt-0"
                  clearable
                  no-resize
                  required
                  rows="2"
                />
              </v-form>
              <!-- / notes -->

              <!-- file upload -->
              <v-form
                v-if="isFileUpload"
                ref="upload-form"
                v-model="uploadFormValid"
                class="col-md-12"
                lazy-validation
              >
                <v-row>
                  <v-col class="pa-0" cols="6">
                    <v-file-input
                      v-if="isFileUpload"
                      v-model="fileInput"
                      :rules="[rules.required]"
                      :show-size="$vuetify.breakpoint.lgAndUp"
                      class="pl-4 pt-0"
                      counter
                      label="File input"
                      required
                      @change="selectFiles"
                    />
                  </v-col>
                  <v-col class="pa-0" cols="6">
                    <v-select
                      v-if="isFileUpload"
                      v-model="document"
                      :class="$vuetify.breakpoint.lgAndUp ? 'pl-2' : 'pl-6'"
                      :items="documentType"
                      :rules="[rules.required]"
                      class="pt-0"
                      color="info"
                      item-text="name"
                      item-value="id"
                      label="Document Type"
                    />
                  </v-col>
                </v-row>
              </v-form>
              <!-- / file upload -->

              <!-- audit -->
              <v-col v-if="isAuditLogTabActive" cols="12">
                <div class="px-4">
                  <v-autocomplete
                    v-model="fieldName"
                    :items="fieldNameOptions"
                    placeholder="Filter the audit log"
                    clearable
                    @change="getAuditLog"
                  />
                </div>
              </v-col>
              <!-- / audit -->
            </v-row>
          </v-col>

          <!-- submit button -->
          <v-col v-if="!isAuditLogTabActive" class="mt-0 pt-6" cols="3">
            <v-btn
              :class="sendBtn.class"
              absolute
              class="mt-2"
              right
              small
              @click="submitAction"
            >
              <v-icon class="mr-1" small>
                {{ sendBtn.img }}
              </v-icon>
              {{ sendBtn.text }}
            </v-btn>
          </v-col>
          <!-- / submit button -->
        </v-row>
      </div>
      <!-- / tab footer -->
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex"
import momentTimezone from "moment-timezone"
import fieldNameOptions from "./field-name-options.json"

const timezone = momentTimezone.tz.guess()

const NOTES_TAB_ID = "tab-1"
const FILE_UPLOAD_TAB_ID = "tab-2"
const SMS_TAB_ID = "tab-3"
const AUDIT_LOG_TAB_ID = "tab-4"

export default {
  name: "ShipmentTabs",
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        alphanumeric: (value) => {
          const pattern = /^[ A-Za-z0-9_@.,()$/#&+-:%]*$/
          return pattern.test(value) || "Invalid value."
        },
      },
      noteValidations: [],
      uploadFormValid: false,
      noteFormValid: false,
      currentTab: 1,
      sendBtn: {
        text: "Send SMS",
        img: "mdi-message-text",
        class: "green darken-1 white--text",
      },
      txtnote: "",
      noteId: 0,
      uploadedFile: "",
      footerBtn1: "Edit",
      footerBtn2: "Delete",
      fileInput: null,
      document: null,
      tabs: [
        {
          title: "Notes",
          href: 1,
          content: [],
        },
        {
          title: "Files",
          href: 2,
          content: [],
        },
        {
          title: "SMS",
          href: 3,
          content: [],
        },
        {
          title: "Audit",
          href: 4,
          content: [],
        },
      ],
      documentType: [
        {
          name: "Bill of Lading",
          id: 1,
        },
        {
          name: "Rate Confirmation",
          id: 2,
        },
        {
          name: "Other",
          id: 3,
        },
        {
          name: "Purchase Order",
          id: 4,
        },
        {
          name: "Carrier Invoice",
          id: 5,
        },
        {
          name: "Customer Invoice Attachment",
          id: 6,
        },
        {
          name: "Customer Invoice",
          id: 7,
        },
      ],
      queryParams: {
        loadId: null,
        limit: 10,
        offset: 0,
      },
      fieldNameOptions,
      fieldName: null,
    }
  },
  computed: {
    ...mapGetters("loadDetail", [
      "loadId",
      "loadingIcon",
      "smsMessages",
      "smsTemplate",
      "fileList",
      "noteList",
      "auditLog",
      "uploadProgress",
    ]),
    isNotes() {
      return this.currentTab === NOTES_TAB_ID
    },
    isFileUpload() {
      return this.currentTab === FILE_UPLOAD_TAB_ID
    },
    isAuditLogTabActive() {
      return this.currentTab === AUDIT_LOG_TAB_ID
    },
  },
  watch: {
    noteList() {
      if (this.noteList.count === 0) {
        this.tabs[0].content = []
      }
      this.getNoteContent()
    },
    fileList() {
      this.getFileContent()
    },
    smsMessages() {
      this.getMessageContent()
    },
    txtnote(val) {
      this.noteValidations = []
    },
  },
  methods: {
    getMessageContent() {
      const getMessage = []
      const smsData = this.smsMessages
      for (let i = 0; i < smsData.length; i++) {
        const sms = {
          date: smsData[i].createdAt,
          text: smsData[i].message,
          userFullName: smsData[i].userFullName,
        }
        getMessage.push(sms)
      }
      this.tabs[2].content = getMessage
    },
    changeTab(value) {
      if (value === SMS_TAB_ID) {
        this.footerBtn1 = ""
        this.footerBtn2 = ""
        this.sendBtn.img = "mdi-message-text"
        this.sendBtn.text = "Send SMS"
        this.$store.dispatch("loadDetail/GET_SMS_MESSAGES", this.loadId)
      } else if (value === NOTES_TAB_ID) {
        this.footerBtn1 = "Edit"
        this.footerBtn2 = "Delete"
        this.sendBtn.text = "Submit"
        this.sendBtn.img = "mdi-note-text"
        this.getNoteRequest()
      } else if (value === FILE_UPLOAD_TAB_ID) {
        this.footerBtn1 = ""
        this.footerBtn2 = "Delete"
        this.sendBtn.text = "Upload"
        this.sendBtn.img = "mdi-cloud-upload"
        this.$store.dispatch("loadDetail/GET_LOAD_FILES", this.loadId)
      } else if (value === AUDIT_LOG_TAB_ID) {
        this.getAuditLog()
      }
    },
    getAuditLog() {
      const queryParams = {
        loadId: this.loadId,
        fieldName: this.fieldName,
        limit: 10,
        offset: 0,
      }
      this.$store.dispatch("loadDetail/GET_AUDIT_LOG", queryParams)
    },
    submitAction() {
      switch (this.sendBtn.text) {
        case "Send SMS": {
          // Fill SMS Template
          this.footerBtn1 = "Send"
          const smsTemplates = []
          const templates = this.smsTemplate
          for (let i = 0; i < templates.length; i++) {
            const template = {
              id: templates[i].id,
              userFullName: templates[i].name,
              text: templates[i].messageLong,
              date: templates[i].createdAt,
            }
            smsTemplates.push(template)
          }
          this.tabs[2].content = smsTemplates
          this.sendBtn.text = "Back"
          break
        }
        case "Back": {
          // Load Message List
          this.sendBtn.text = "Send SMS"
          this.footerBtn1 = ""
          this.getMessageContent()
          break
        }
        case "Submit": {
          // Submit Notes
          this.noteValidations = [(v) => !!v || "Note cannot be empty"]
          setTimeout(() => {
            if (this.$refs["note-form"].validate()) {
              const payload = {
                loadId: this.loadId,
                text: this.txtnote,
              }
              this.$store.dispatch("loadDetail/SUBMIT_NOTE", payload)
              this.txtnote = ""
            }
          })
          break
        }
        case "Upload": {
          // Upload File
          if (this.$refs["upload-form"].validate()) {
            if (
              this.uploadedFile.name !== undefined &&
              this.document !== null
            ) {
              const flData = new FormData()
              flData.append("file", this.uploadedFile)
              flData.append("document_type", this.document)
              const upFile = {
                load: this.loadId,
                fmData: flData,
              }
              this.$store.dispatch("loadDetail/UPLOAD_FILE", upFile)
              this.fileInput = null
              this.document = null
              this.$refs["upload-form"].resetValidation()
            }
          }
          break
        }
        case "Update": {
          // Update Note
          const editNote = {
            id: this.noteId,
            text: this.txtnote,
            loadId: this.loadId,
          }
          this.$store.dispatch("loadDetail/UPDATE_NOTE", editNote)
          this.txtnote = ""
          this.sendBtn.text = "Submit"
          break
        }
      }
    },
    editAndSendEvent(value, object) {
      if (value === 3) {
        // Send SMS button event
        const payload = {
          loadId: this.loadId,
          template: object.id,
        }
        this.$store.dispatch("loadDetail/SEND_SMS", payload)
      } else if (value === 1) {
        // Edit Note event
        this.txtnote = object.text
        this.noteId = object.id
        this.sendBtn.text = "Update"
      }
    },
    deleteItemEvent(value, object) {
      if (value === 2) {
        // Delete File
        const delFile = {
          fileId: object.id,
          loadId: this.loadId,
        }
        this.$store.dispatch("loadDetail/DELETE_FILE", delFile)
      } else if (value === 1) {
        // Delete Note
        const delNote = {
          noteId: object.id,
          loadId: this.loadId,
        }
        this.$store.dispatch("loadDetail/DELETE_NOTE", delNote).then((data) => {
          this.getNoteRequest()
        })
      }
    },
    getFileContent() {
      const getFiles = []
      const fileData = this.fileList
      for (let i = 0; i < fileData.length; i++) {
        const file = {
          id: fileData[i].id,
          date: fileData[i].timeStamp,
          text:
            '<a href="' +
            fileData[i].fileURL +
            '" target="_blank">' +
            fileData[i].fileName +
            "</a>",
          userFullName: fileData[i].uploadedBy,
          documentTypeDisplay: fileData[i].documentTypeDisplay,
        }
        getFiles.push(file)
      }
      this.tabs[1].content = getFiles
    },
    getNoteContent() {
      if (!this.noteList.previous) {
        this.tabs[0].content = []
      }
      const noteData = this.noteList.results
      for (let i = 0; i < noteData.length; i++) {
        const note = {
          id: noteData[i].id,
          date: noteData[i].timeStamp,
          text: noteData[i].text,
          userFullName: noteData[i].author,
        }
        this.tabs[0].content.push(note)
      }
    },
    getNoteRequest() {
      this.queryParams = {
        loadId: this.loadId,
        limit: 10,
        offset: 0,
      }
      this.$store.dispatch("loadDetail/GET_NOTES", this.queryParams)
    },
    selectFiles(event) {
      // Set Uploaded file in variable
      this.uploadedFile = event
    },
    formatDate(value) {
      if (value) {
        return momentTimezone(value).tz(timezone).format("MM-DD-YYYY HH:mm z")
      }
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        if (this.currentTab === NOTES_TAB_ID && this.noteList.next) {
          const queryParams = this.getQueryParamsByUrl(this.noteList.next)
          this.$store.dispatch("loadDetail/GET_NOTES", queryParams)
        }
        if (this.currentTab === AUDIT_LOG_TAB_ID && this.auditLog.next) {
          const queryParams = this.getQueryParamsByUrl(this.auditLog.next)
          queryParams.fieldName = this.fieldName
          this.$store.dispatch("loadDetail/GET_AUDIT_LOG", queryParams)
        }
      }
    },
    getQueryParamsByUrl(url) {
      const params = new URL(url).searchParams
      return {
        loadId: this.loadId,
        limit: params.get("limit"),
        offset: params.get("offset"),
      }
    },
  },
}
</script>

<style>
.v-text-field > .v-input__control > .v-input__slot {
  margin-bottom: 0;
}
</style>
