<template>
  <div class="backend-csr-autocomplete">
    <v-autocomplete
      v-model="carrierSalesRep"
      clearable
      :items="carrierSalesRepList"
      :loading="loading"
      :search-input.sync="search"
      color="info"
      item-text="text"
      item-value="id"
      label="CSR"
      return-object
      close-on-content-click
      class="mt-n2"
      @keydown.enter="$event.stopPropagation()"
      @blur="pushCSR()"
      @click:clear="removeCSR()"
    />
  </div>
</template>

<script>
import debounce from "lodash/debounce"
import api from "@/api/api"
import { mapGetters } from "vuex"
export default {
  name: "CsrAutoComplete",
  data() {
    return {
      carrierSalesRep: null,
      loading: false,
      search: "",
      carrierSalesReps: [],
    }
  },
  computed: {
    ...mapGetters("loadDetail", {
      shipmentDetail: "shipmentDetail",
    }),
    carrierSalesRepList() {
      return this.carrierSalesReps.map((csrUser) => {
        const name = csrUser.text
        return Object.assign({}, csrUser, { name })
      })
    },
  },
  watch: {
    search(val) {
      if (!val || val.length === 0) {
        this.carrierSalesReps = []
        return
      }
      if (this.carrierSalesRep) {
        if (val !== this.carrierSalesRep.name) {
          if (this.loading) return
          this.loading = true
          this.getSearch()
        }
      } else {
        if (this.loading) return
        this.loading = true
        this.getSearch()
      }
    },
  },
  mounted() {
    if (this.shipmentDetail.carrierSalesRep) {
      this.carrierSalesRep = {
        id: this.shipmentDetail.carrierSalesRep.id,
        name: this.shipmentDetail.carrierSalesRep.name,
        text: this.shipmentDetail.carrierSalesRep.name,
      }
      this.carrierSalesReps = [
        {
          id: this.shipmentDetail.carrierSalesRep.id,
          name: this.shipmentDetail.carrierSalesRep.name,
          text: this.shipmentDetail.carrierSalesRep.name,
        },
      ]
    }
  },
  methods: {
    getSearch: debounce(function () {
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/accounts/csr-front-end-autocomplete/`,
          {
            params: {
              q: this.search,
            },
          }
        )
        .then((res) => {
          this.carrierSalesReps = res.data.results
          this.loading = false
        })
        .catch((err) => {
          const payload = {
            status: true,
            text: "Failed to retrieve carrier sales reps.",
            error: err,
          }
          this.$store.commit("main/SET_SNACKBAR", payload)
          this.loading = false
        })
    }, 500),
    pushCSR() {
      if (this.carrierSalesRep) {
        this.$store.commit("loadDetail/setCsr", this.carrierSalesRep)
      }
    },
    removeCSR() {
      this.carrierSalesRep = null
      this.$store.commit("loadDetail/setCsr", this.carrierSalesRep)
    },
  },
}
</script>
