<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-card-title
          class="blue-grey--text lighten-3 text-subtitle-1 float-left"
        >
          #{{ shipmentDetail.id }}
        </v-card-title>
      </v-col>
      <v-col class="text-right" cols="6">
        <v-btn
          :disabled="disableStatusButton"
          color="grey"
          small
          text
          @click="saveLoadUpdate()"
        >
          Save
        </v-btn>
      </v-col>
    </v-row>

    <v-form
      ref="shipment-form"
      v-model="shipmentDetailFormValid"
      lazy-validation
    >
      <v-row class="pl-3">
        <v-col
          :cols="$vuetify.breakpoint.lgAndUp ? '6' : '12'"
          class="pt-0 pb-0"
        >
          <customer-auto-complete :default-customer="shipmentDetail.customer" />
        </v-col>
      </v-row>

      <v-row class="pl-3">
        <v-col class="pt-0 pb-0" cols="6">
          <v-menu
            ref="pickupDate"
            v-model="pickupDateMenu"
            :close-on-content-click="false"
            min-width="290px"
            offset-x
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="shipmentDetail.pickupDate"
                v-bind="attrs"
                v-on="on"
                class="pt-0 pb-0"
                label="Pickup Date"
                readonly
              />
            </template>
            <v-date-picker
              v-model="shipmentDetail.pickupDate"
              no-title
              scrollable
              @input="pickupDateMenu = false"
            />
          </v-menu>
        </v-col>
        <v-col class="pt-0 pb-0" cols="6">
          <v-menu
            ref="deliveryDate"
            v-model="deliveryDateMenu"
            :close-on-content-click="false"
            min-width="290px"
            offset-x
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="shipmentDetail.deliveryDate"
                v-bind="attrs"
                v-on="on"
                class="pt-0 pb-0"
                label="Delivery Date"
                readonly
              />
            </template>
            <v-date-picker
              v-model="shipmentDetail.deliveryDate"
              no-title
              scrollable
              @input="deliveryDateMenu = false"
            />
          </v-menu>
        </v-col>
      </v-row>

      <v-row class="pl-3">
        <v-col
          :cols="$vuetify.breakpoint.lgAndUp ? '4' : '6'"
          class="pt-0 pb-0"
        >
          <equipment-type-auto-complete :equipment="equipmentType" />
        </v-col>
        <v-col
          :cols="$vuetify.breakpoint.lgAndUp ? '4' : '6'"
          class="pt-0 pb-0"
        >
          <order-type-auto-complete :model="shipmentOrderType" />
        </v-col>
        <v-col
          :cols="$vuetify.breakpoint.lgAndUp ? '4' : '6'"
          class="pt-0 pb-0 mt-n3"
        >
          <agent-auto-complete :default-agent="shipmentDetail.agent" />
        </v-col>
        <v-col
          :cols="$vuetify.breakpoint.lgAndUp ? '4' : '6'"
          class="pt-0 pb-0"
        >
          <csr-auto-complete />
        </v-col>
        <v-col
          :cols="$vuetify.breakpoint.lgAndUp ? '4' : '6'"
          class="pt-0 pb-0"
        >
          <v-text-field
            v-model="shipmentDetail.customerReferenceId"
            class="pt-0"
            label="Customer Ref. ID"
            @keydown.enter.native="saveLoadUpdate()"
          />
        </v-col>
        <v-col
          :cols="$vuetify.breakpoint.lgAndUp ? '4' : '6'"
          class="pt-0 pb-0"
        >
          <v-text-field
            v-model="shipmentDetail.poNumber"
            class="pt-0"
            label="PO Number"
            @keydown.enter.native="saveLoadUpdate()"
          />
        </v-col>
        <v-col
          :cols="$vuetify.breakpoint.lgAndUp ? '4' : '6'"
          class="pt-0 pb-0"
        >
          <v-select
            v-model="shipmentDetail.mode"
            :items="modes"
            class="pt-0"
            clearable
            item-text="name"
            item-value="id"
            label="Mode Type"
          />
        </v-col>
        <v-col
          :cols="$vuetify.breakpoint.lgAndUp ? '4' : '6'"
          class="pt-0 pb-0"
        >
          <v-text-field
            v-model="shipmentDetail.carrierInvoiceNumber"
            class="pt-0"
            label="Carrier Invoice Number"
            @keydown.enter.native="saveLoadUpdate()"
          />
        </v-col>
        <v-col
          :cols="$vuetify.breakpoint.lgAndUp ? '4' : '6'"
          class="pt-0 pb-0"
        >
          <v-text-field
            v-model="shipmentDetail.billingReferenceNumber"
            class="pt-0"
            label="Billing Reference Number"
            @keydown.enter.native="saveLoadUpdate()"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import EquipmentTypeAutoComplete from "@/views/pages/components/EquipmentTypeAutoComplete"
import OrderTypeAutoComplete from "@/views/pages/components/OrderTypeAutoComplete"
import AgentAutoComplete from "@/views/pages/components/AgentAutoComplete"
import csrAutoComplete from "@/views/pages/components/CsrAutoComplete"
import CustomerAutoComplete from "@/views/pages/components/CustomerAutoComplete"
import moment from "moment"
import common from "@/utils/common"

export default {
  name: "ShipmentDetailEdit",
  components: {
    EquipmentTypeAutoComplete,
    CustomerAutoComplete,
    AgentAutoComplete,
    OrderTypeAutoComplete,
    csrAutoComplete,
  },
  data() {
    return {
      valid: false,
      pickupDateMenu: false,
      deliveryDateMenu: false,
      shipmentDetailFormValid: false,
    }
  },
  computed: {
    ...mapGetters("loadDetail", {
      shipmentDetail: "shipmentDetail",
      shipmentDetailEdit: "shipmentDetailEdit",
      shipperInfo: "shipperInfo",
      consigneeInfo: "consigneeInfo",
      carrierInfo: "carrierInfo",
      loadInfo: "loadInfo",
    }),
    ...mapGetters("generic", {
      equipment: "equipment",
      customerId: "customerId",
      agentDetail: "agentDetail",
    }),
    ...mapGetters("loadDetail", ["shipmentDetail"]),
    ...mapGetters("generic", ["equipment", "customerId", "modes"]),
    shipmentOrderType() {
      return {
        id: this.shipmentDetail.orderType,
        name: this.shipmentDetail.orderTypeDisplay,
      }
    },
    equipmentType() {
      return {
        id: this.shipmentDetail.equipmentType,
        name: this.shipmentDetail.equipmentType,
      }
    },
    disableStatusButton() {
      return common.methods.disableButtonBaseOnLoadStatus(
        this.shipmentDetail,
        this.agentDetail
      )
    },
  },
  methods: {
    saveLoadUpdate() {
      if (this.$refs["shipment-form"].validate()) {
        const payload = {
          id: this.shipmentDetail.id,
          pickup_date: moment(String(this.shipmentDetail.pickupDate)).format(
            "YYYY-MM-DD"
          ),
          delivery_date: moment(
            String(this.shipmentDetail.deliveryDate)
          ).format("YYYY-MM-DD"),
          equipment_type: this.equipment,
          customer_company: this.customerId
            ? this.customerId
            : this.shipmentDetail.customer.id,
          agent: this.shipmentDetail.agent
            ? this.shipmentDetail.agent.id
            : null,
          carrier_sales_rep: this.shipmentDetail.carrierSalesRep
            ? this.shipmentDetail.carrierSalesRep.id
            : null,
          order_type: this.shipmentDetail.orderType,
          po_number: this.shipmentDetail.poNumber,
          customer_reference_id: this.shipmentDetail.customerReferenceId,
          mode: this.shipmentDetail.mode,
          carrier_invoice_number: this.shipmentDetail.carrierInvoiceNumber,
          billing_reference_number: this.shipmentDetail.billingReferenceNumber,
        }

        this.$store
          .dispatch("loadDetail/PATCH_LOAD_DETAIL", payload)
          .then(() => {
            this.$store.commit("loadDetail/setShipmentDetailEditing", false)
          })
          .catch(() => {
            // Swallow to avoid Sentry throwing an error
          })
      }
    },
  },
}
</script>
