<template>
  <div>
    <v-autocomplete
      v-model="orderType"
      :items="orderTypes"
      :loading="loading"
      :search-input.sync="search"
      color="info"
      clearable
      item-text="name"
      item-value="id"
      label="Order Type"
      return-object
      required
      close-on-content-click
      :rules="[(v) => !!v || `Order type is required`]"
      class="mt-n2"
      @keydown.enter="$event.stopPropagation()"
      @blur="pushOrderType()"
      @click:clear="removeOrderType()"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "OrderTypeAutoComplete",
  props: {
    model: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      orderType: null,
      loading: false,
      search: "",
    }
  },
  computed: {
    ...mapGetters("generic", ["orderTypes"]),
    ...mapGetters("loadDetail", ["shipmentDetail"]),
  },
  mounted() {
    if (this.model != null) {
      this.orderType = {
        id: this.model.id,
        name: this.model.name,
      }
    }
  },
  methods: {
    pushOrderType() {
      if (this.orderType) {
        this.$store.commit("loadDetail/setOrderType", this.orderType)
      }
    },
    removeOrderType() {
      this.orderType = {
        id: null,
        name: "",
      }
      this.$store.commit("loadDetail/setOrderType", this.orderType)
    },
  },
}
</script>
