<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        :class="btnClass"
        :color="color"
        :disabled="disabled"
        :href="buttonRedirect"
        :target="target"
        small
        @click="sendAuthenticatedApiRequest()"
      >
        <v-icon left small>
          {{ btnIcon }}
        </v-icon>
        {{ buttonText }}
      </v-btn>
    </template>
    <span>{{ buttonText }}</span>
  </v-tooltip>
</template>
<script>
import api from "@/api/api.js"
import common from "@/utils/common"

export default {
  props: {
    buttonText: {
      type: String,
      default: null,
    },
    action: {
      type: String,
      default: "",
    },
    btnIcon: {
      type: String,
      default: "",
    },
    btnClass: {
      type: String,
      default: "",
    },
    href: {
      type: String,
      default: "",
    },
    target: {
      type: String,
      default: "",
    },
    apiCall: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonRedirect() {
      if (this.apiCall) {
        return null
      } else {
        return this.href
      }
    },
  },
  methods: {
    sendAuthenticatedApiRequest() {
      if (this.apiCall) {
        api
          .post(this.href)
          .then((response) => {
            const payload = {
              status: true,
              text: response.data.success,
              type: common.data.snackbarType.success,
            }
            this.$store.commit("main/SET_SNACKBAR", payload)
          })
          .catch((error) => {
            const payload = {
              status: true,
              text: error.response?.data?.error ?? "An unexpected error has occured.",
              type: common.data.snackbarType.error,
            }
            this.$store.commit("main/SET_SNACKBAR", payload)
          })
      }
    },
  },
}
</script>
